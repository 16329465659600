@use "@design-system/ui-kit/src/assets/styles/tools/tools";
@use "@design-system/ui-kit/src/assets/styles/themes/themes";

// Define your custom theme
$defaultTheme: (
        "colors": (
                "backgroundPrimary": #fff,
                "backgroundSecondary": #fcfbfd,
                "backgroundTertiary": #f7f7fa,
                "backgroundQuaternary": #efeff5,
                "backgroundFifth": #948f98,
                "backgroundSixth": #625c66,
                "backgroundSeventh": #29272b,
                "backgroundActionConfirm": #f2f9ec,
                "backgroundActionNotice": #fcf6ed,
                "backgroundActionError": #fcf0f0,
                "backgroundActionBrand": #eff4ff,
                "backgroundActionLink": #eef5fe,
                "interfacePrimary": #5a545f,
                "interfaceSecondary": #6e6873,
                "interfaceTertiary": #8c8691,
                "interfaceQuaternary": #b4aeb9,
                "interfaceOnsurface": #fff,
                "interfaceFifth": #ddd9e0,
                "interfaceSixth": #f3eff5,
                "interfaceSeventh": #f8f6fa,
                "interfaceBluePrimary": #93B800,
                "interfaceBlueSecondary": #92d464,
                "interfaceBlueTertiary": #6aac3c,
                "interfaceBlueQuaternary": #6aac3c,
                "interfaceAdPrimary": theme('colors.brand.primary'),
                "interfaceAdSecondary": theme('colors.brand.primary'),
                "interfaceAdTertiary": theme('colors.interface.success'),
                "interfaceAdQuaternary": #007bee,
                "interfaceFocusPrimary": #f6d83b,
                "labelPrimary": #0b1116,
                "labelSecondary": #333a40,
                "labelTertiary": #5a6065,
                "labelQuaternary": #888c8f,
                "labelOnsurface": #fff,
                "labelLink": #43a5dc,
                "labelFifth": #b5b8bb,
                "labelSixth": #d8dadc,
                "labelSeventh": #eeeff1,
                "labelError": #e47470,
                "labelNotice": #d79735,
                "labelConfirm": #7ec050,
                "labelBrand": #93B800,
                "actionConfirmPrimary": #7ec050,
                "actionConfirmSecondary": #92d464,
                "actionConfirmTertiary": #6aac3c,
                "actionNoticePrimary": #ebab49,
                "actionNoticeSecondary": #ffbf5d,
                "actionNoticeTertiary": #d79735,
                "actionErrorPrimary": #e47470,
                "actionErrorSecondary": #f88884,
                "actionErrorTertiary": #d0605c,
        )
);

// Use the mixins to generate CSS variables for your custom theme
[data-ui-kit-theme="capyfast"] {
  @include tools.generateCssVars(
                  tools.mapDeepMerge(themes.$themeLight, $defaultTheme)
  );
}
