.table{
display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  > * {
    padding: theme('padding.2') theme('padding.6');
  }

  .emptyContent{
    grid-column: 1 / span 4;
  }
}
