@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Fira Sans", system-ui, sans-serif;
    color: theme('colors.label.primary');
  }
}

body{
  background: linear-gradient(141deg, #F7F7F7 2.72%, #E0E0EF 82.9%);
  background-size: 100% 100%;
  min-height: 100dvh;
}

#root{
  min-height: 100dvh;
}

@import "themes/default";
