:global(#modal-root) {
  .overflow:last-child {
    background: rgba(0, 0, 0, .4);
  }
}

.overflow {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  container: modalWrapper / inline-size;
}

.wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
          'content'
          'footer';
  position: absolute;
  background: white;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 38.4375rem;
  max-height: 100dvh;
  box-shadow: 0 .75rem 1.5rem rgba(0, 0, 0, 0.14);
  border-radius: .375rem;
  z-index: 1001;
  padding: 0;
  animation-duration: .25s;
  overflow: hidden;
  //animation-name: showModal;

  &.dialog {
    width: calc(100% - 2rem);
  }

  img {
    max-width: 100%;
  }

  .footer {
    position: relative;
    grid-area: footer;
    padding: 1rem 2.5rem 2rem;
    background: white;

    &:before {
      display: block;
      position: absolute;
      top: -.75rem;
      left: 0;
      width: 100%;
      height: .75rem;
      //background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      content: '';
    }
  }

  .content {
    padding: 2rem 2.5rem 1rem;
    overflow: auto;
    grid-area: content;
    max-height: 100%;
    &.withoutFooter{
      padding: 2rem 2.5rem 2rem;
    }
  }

  &.mobile {
    .content {
      padding: 1.5rem 1rem .5rem;
    }

    .footer {
      padding: .5rem 1rem 1rem;
    }
  }
}

@container modalWrapper (width < 540px) {
  .wrapper.adaptive {
    padding: 0 !important;
  }

  .wrapper.adaptive .modal {
    align-items: flex-end !important;
    top: auto !important;
    bottom: 0 !important;
    transform: translateY(0%);
    border-radius: .375rem .375rem 0 0;

    &.dialog {
      width: 100%;
    }
  }
}

//@keyframes showModal {
//  from {
//    scale: .9;
//  }
//
//  to {
//    scale: 1;
//  }
//}

.close {
  position: absolute;
  right: .375rem;
  top: .375rem;
  cursor: pointer;
}

.actions {
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
  text-align: right;
  margin-top: 1.875rem;
}
