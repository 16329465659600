.wrapper{
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

.container{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.col1{
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
