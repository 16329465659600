.item{
  padding: 0.625rem 0.75rem 0.625rem 0.625rem;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: background-color, color .5s ease;
  cursor: pointer;
  &:hover,
  &:focus{
    color: theme('colors.label.secondary');
    background: theme('colors.interface.seventh');
  }
}
