.aside {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 0.59rem;
  height: max-content;
  flex: 1 1 100%;
  min-height: calc(100dvh - 10rem);
}

.navigation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
}

.item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 1.625rem;
  height: 0.125rem;
  background-color: theme('colors.interface.seventh');
  margin: theme('margin.4') 0;
}
