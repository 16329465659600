.layoutContainer {
  container: Layout/inline-size;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
}

.layout {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: theme('gap.4');
  width: 100%;
  max-width: 111.5rem;
  grid-template-areas:
          'topmenu topmenu'
          'aside main'
          'footer footer';
  &.noNavigation{
    grid-template-areas:
          'topmenu topmenu'
          'main main'
          'footer footer';
  }
}

.topmenu {
  grid-area: topmenu;
}

.aside {
  grid-area: aside;
  display: flex;
  flex-flow: column nowrap;
}

.content {
  grid-area: main;
  @apply flex flex-col
}

.footer {
  padding-top: theme('padding.4');
  grid-area: footer
}


